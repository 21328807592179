import React, { useEffect, lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ProgressBar } from 'primereact/progressbar';
import logo from './bibot-icon.png';
import Auth from './utils/Auth';
import './App.css';
import 'primereact/resources/themes/arya-green/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import "primeflex/primeflex.css";

const Login = lazy(() => import('./sub-components/login/Login'));
const Chat = lazy(() => import('./sub-components/chat/Chat'));

function AuthenticatedChat(props) {
  return Auth(Chat, logo)(props);
}

function App() {
  useEffect(() => {
    document.title = "BiBOT";
  }, []);

  return (
    <BrowserRouter basename="/bibot">
      <div className="App">
        <Suspense fallback={<div><ProgressBar mode="indeterminate" style={{ height: '3px' }}></ProgressBar></div>}>
          <Routes>
            <Route path="/" element={<Login logo={logo} />} />
            <Route path="/chat" element={<AuthenticatedChat />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Suspense>
      </div>
    </BrowserRouter>
  );
}

export default App;
import React from 'react';
import { Navigate } from 'react-router-dom';
import getCookie from './Cookie';

const Auth = (WrappedComponent, logo) => {
  return (props) => {
    const tokenFromCookie = getCookie(process.env.REACT_APP_COOKIE);
    if (!tokenFromCookie) {
      return <Navigate to="/" replace />;
    }
    return <WrappedComponent {...props} logo={logo} />;
  };
};

export default Auth;